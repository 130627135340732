import React from "react";
import styled from "styled-components";
import "leaflet/dist/leaflet.css";
import {
  MapContainer,
  TileLayer,
  Polygon,
  CircleMarker,
  Popup,
} from "react-leaflet";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 5% 10% 10% 10%;
`;

const Languages = () => {
  const GayoLanguage = [4.62, 96.84];
  const KluetLanguage = [3.07, 97.35];
  const AlasLanguage = [3.49, 97.81];
  const SingkilLanguage = [2.28, 97.8];
  const DairiLanguage = [2.74, 98.33];
  const KaroLanguage = [3.1, 98.49];
  const SimalungunLanguage = [2.96, 99.07];
  const TobaLanguage = [2.33, 99.07];
  const AngkolaLanguage = [1.5, 99.62];
  const MandailingLanguage = [1.05, 99.73];
  const HalobanLanguage = [2.23, 97.23];
  const SimeulueLanguage = [2.47, 96.38];
  const LeukonLanguage = [2.88, 95.82];
  const SigulaiLanguage = [2.59, 96.0];
  const NiasLanguage = [1.28, 97.61];
  const MentawaiLanguage = [-1.61, 99.21];
  const EngganoLanguage = [-5.42, 102.37];
  const NasalLanguage = [-4.88, 103.55];

  const Nias = [
    [1.433566223, 96.84448242],
    [0.966751, 97.14111328],
    [0.692121839, 97.33886719],
    [0.439448816, 97.71240234],
    [0.087890591, 97.82226563],
    [-0.043945308, 97.73986816],
    [-0.175780974, 97.82226563],
    [-0.175780974, 98.08044434],
    [-0.587758246, 98.21777344],
    [-0.725078302, 98.47595215],
    [-0.620715397, 98.65722656],
    [-0.032958983, 98.6517334],
    [-0.010986328, 98.83300781],
    [0.076904274, 98.95935059],
    [0.241698502, 98.8494873],
    [0.258177837, 98.36608887],
    [0.565786703, 98.04748535],
    [1.038151198, 98.0090332],
    [1.735574363, 97.35534668],
    [1.433566223, 96.84448242],
  ];

  const Mentawai = [
    [-0.873379261, 98.60778809],
    [-1.224882274, 98.45947266],
    [-3.617589124, 100.2172852],
    [-3.612106889, 100.7171631],
    [-3.354405358, 100.81604],
    [-0.851409094, 99.03076172],
    [-0.873379261, 98.60778809],
  ];

  const langMarkerRadius = 10;
  const langMarkerOptions = {
    color: "black",
    weight: "1",
    opacity: "1",
    fillOpacity: "0.8",
  };
  const GayoColor = { fillColor: "#ff00ff" };
  const BatakColor = { fillColor: "#ff0000" };
  const NBIColor = { fillColor: "#ffaa00" };
  const CBIColor = { fillColor: "#ffff00" };
  const MentawaiColor = { fillColor: "#00ff00" };
  const EngganoColor = { fillColor: "#008080" };
  const NasalColor = { fillColor: "#8080ff" };

  return (
    <Container>
      <MapContainer
        center={[0, 100]}
        zoom={6}
        style={{ height: "80vh", border: "2px solid gray" }}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <CircleMarker
          center={GayoLanguage}
          pathOptions={{
            ...langMarkerOptions,
            ...GayoColor,
          }}
          radius={langMarkerRadius}
        >
          <Popup>Gayo</Popup>
        </CircleMarker>
        <CircleMarker
          center={KluetLanguage}
          pathOptions={{
            ...langMarkerOptions,
            ...BatakColor,
          }}
          radius={langMarkerRadius}
        >
          <Popup>Kluet</Popup>
        </CircleMarker>
        <CircleMarker
          center={AlasLanguage}
          pathOptions={{
            ...langMarkerOptions,
            ...BatakColor,
          }}
          radius={langMarkerRadius}
        >
          <Popup>Alas</Popup>
        </CircleMarker>
        <CircleMarker
          center={SingkilLanguage}
          pathOptions={{
            ...langMarkerOptions,
            ...BatakColor,
          }}
          radius={langMarkerRadius}
        >
          <Popup>Singkil</Popup>
        </CircleMarker>
        <CircleMarker
          center={DairiLanguage}
          pathOptions={{
            ...langMarkerOptions,
            ...BatakColor,
          }}
          radius={langMarkerRadius}
        >
          <Popup>Dairi</Popup>
        </CircleMarker>
        <CircleMarker
          center={KaroLanguage}
          pathOptions={{
            ...langMarkerOptions,
            ...BatakColor,
          }}
          radius={langMarkerRadius}
        >
          <Popup>Karo</Popup>
        </CircleMarker>
        <CircleMarker
          center={SimalungunLanguage}
          pathOptions={{
            ...langMarkerOptions,
            ...BatakColor,
          }}
          radius={langMarkerRadius}
        >
          <Popup>Simalungun</Popup>
        </CircleMarker>
        <CircleMarker
          center={TobaLanguage}
          pathOptions={{
            ...langMarkerOptions,
            ...BatakColor,
          }}
          radius={langMarkerRadius}
        >
          <Popup>Toba</Popup>
        </CircleMarker>
        <CircleMarker
          center={AngkolaLanguage}
          pathOptions={{
            ...langMarkerOptions,
            ...BatakColor,
          }}
          radius={langMarkerRadius}
        >
          <Popup>Angkola</Popup>
        </CircleMarker>
        <CircleMarker
          center={MandailingLanguage}
          pathOptions={{
            ...langMarkerOptions,
            ...BatakColor,
          }}
          radius={langMarkerRadius}
        >
          <Popup>Mandailing</Popup>
        </CircleMarker>
        <CircleMarker
          center={HalobanLanguage}
          pathOptions={{
            ...langMarkerOptions,
            ...NBIColor,
          }}
          radius={langMarkerRadius}
        >
          <Popup>Haloban</Popup>
        </CircleMarker>
        <CircleMarker
          center={SimeulueLanguage}
          pathOptions={{
            ...langMarkerOptions,
            ...NBIColor,
          }}
          radius={langMarkerRadius}
        >
          <Popup>Simeulue</Popup>
        </CircleMarker>
        <CircleMarker
          center={LeukonLanguage}
          pathOptions={{
            ...langMarkerOptions,
            ...NBIColor,
          }}
          radius={langMarkerRadius}
        >
          <Popup>Leukon</Popup>
        </CircleMarker>
        <CircleMarker
          center={SigulaiLanguage}
          pathOptions={{
            ...langMarkerOptions,
            ...CBIColor,
          }}
          radius={langMarkerRadius}
        >
          <Popup>Sigulai</Popup>
        </CircleMarker>
        <CircleMarker
          center={NiasLanguage}
          pathOptions={{
            ...langMarkerOptions,
            ...CBIColor,
          }}
          radius={langMarkerRadius}
        >
          <Popup>Nias</Popup>
        </CircleMarker>
        <CircleMarker
          center={MentawaiLanguage}
          pathOptions={{
            ...langMarkerOptions,
            ...MentawaiColor,
          }}
          radius={langMarkerRadius}
        >
          <Popup>Mentawai</Popup>
        </CircleMarker>
        <CircleMarker
          center={EngganoLanguage}
          pathOptions={{
            ...langMarkerOptions,
            ...EngganoColor,
          }}
          radius={langMarkerRadius}
        >
          <Popup>Enggano</Popup>
        </CircleMarker>
        <CircleMarker
          center={NasalLanguage}
          pathOptions={{
            ...langMarkerOptions,
            ...NasalColor,
          }}
          radius={langMarkerRadius}
        >
          <Popup>Nasal</Popup>
        </CircleMarker>
        {false && <Polygon pathOptions={{ color: "red" }} positions={Nias} />}
        {false && (
          <Polygon pathOptions={{ color: "blue" }} positions={Mentawai} />
        )}
      </MapContainer>
    </Container>
  );
};

export default Languages;

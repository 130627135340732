import React, { useState } from "react";
import styled from "styled-components";
import { sources } from "../sources";
import SCDTable from "../components/SCDTable";
import { useSearchParams } from "react-router-dom";
import Source from "./Source";

const Container = styled.div`
  width: 90%;
  margin: 5%;
  display: flex;
  flex-direction: column;
`;

const SourcesHeader = styled.h1`
  text-align: center;
`;

const SourcesOptionsContainer = styled.div`
  margin: 20px 20px 0px 20px;
  display: flex;
  flex-direction: column;
`;

const SourcesOptionsHeader = styled.h2`
  font-size: 20px;
  text-align: center;
`;

const SourcesOptionsFields = styled.div`
  display: flex;
  text-align: center;
`;

const SourcesOptionsField = styled.label`
  flex: 1;
`;

const BackButtonContainer = styled.div`
  padding: 10px 0px;
`;

const BackButton = styled.button``;

const Sources = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const headers = [
    "ID",
    "Title",
    "Author",
    "Year",
    "Subject",
    "Analysis",
    "Type",
    "Worldcat",
  ];

  const headersToFields = {
    ID: "id",
    Title: "title",
    Author: "author",
    Year: "year",
    Subject: "subjectLanguage",
    Analysis: "analysisLanguage",
    Type: "sourceType",
    Worldcat: "worldcat",
  };

  const [headersViews, setHeadersViews] = useState(
    headers.reduce((accumulator, header) => {
      return { ...accumulator, [header]: true };
    }, {})
  );

  const getInputField = (field) => {
    return (
      <input
        type="checkbox"
        checked={headersViews[field]}
        onChange={() =>
          setHeadersViews({ ...headersViews, [field]: !headersViews[field] })
        }
      ></input>
    );
  };

  const getSource = (sourceID) => {
    const source = sources.reduce(
      (accumulator, source) =>
        source["id"] === sourceID ? source : accumulator,
      {}
    );
    return <Source {...source} />;
  };

  const setSource = (sourceID) => {
    setSearchParams({ ...searchParams, sourceID: sourceID });
  };

  return (
    <Container>
      <SourcesHeader>Sources</SourcesHeader>
      <SourcesOptionsContainer>
        <SourcesOptionsHeader>Options</SourcesOptionsHeader>
        <SourcesOptionsFields>
          <SourcesOptionsField>
            {getInputField("Subject")}
            View Subject Language
          </SourcesOptionsField>
          <SourcesOptionsField>
            {getInputField("Analysis")}
            View Analysis Language
          </SourcesOptionsField>
        </SourcesOptionsFields>
      </SourcesOptionsContainer>
      {!searchParams.get("sourceID") ? (
        <SCDTable
          data={sources}
          headers={headers}
          headersToFields={headersToFields}
          headersViews={headersViews}
          type={"sourcesTable"}
          setSource={setSource}
        />
      ) : (
        <React.Fragment>
          <BackButtonContainer>
            <BackButton onClick={() => setSource("")}>Back</BackButton>
          </BackButtonContainer>
          {getSource(searchParams.get("sourceID"))}
        </React.Fragment>
      )}
    </Container>
  );
};

export default Sources;

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 5% 10% 10% 10%;
`;

const AboutHeader = styled.h1`
  padding-bottom: 0.5em;
`;

const AboutSubHeader = styled.h2`
  padding-bottom: 0.5em;
`;

const AboutParagraph = styled.p`
  padding-bottom: 0.5em;
`;

const AboutUL = styled.ul`
  margin-left: 1em;
`;

const AboutOL = styled.ol`
  margin-left: 1em;
`;

const AboutLI = styled.li``;

const About = () => {
  return (
    <Container>
      <AboutHeader>About</AboutHeader>
      <AboutParagraph>
        The e-HSL is intended to be a one-stop online resource for linguistic
        topics related to the Sumatran language family.
      </AboutParagraph>
      <AboutParagraph>
        Unless cited otherwise, all research presented here is the original work
        of the primary author. Please see the list of citations on the "Sources"
        page in order to find information regarding a specific language.
      </AboutParagraph>
      <AboutSubHeader>Roadmap</AboutSubHeader>
      <AboutParagraph>
        Planned developments of the e-HSL include:
        <AboutUL>
          <AboutLI>
            General
            <AboutOL>
              <AboutLI>Full site Indonesian translation</AboutLI>
              <AboutLI>Working site search</AboutLI>
            </AboutOL>
          </AboutLI>
          <AboutLI>
            Home View
            <AboutOL>
              <AboutLI>Full project description and introduction</AboutLI>
            </AboutOL>
          </AboutLI>
          <AboutLI>Dictionary View</AboutLI>
          <AboutLI>
            Entries View
            <AboutOL>
              <AboutLI>Regex searching</AboutLI>
              <AboutLI>
                Vertical alignment for multiple language citations in a single
                row
              </AboutLI>
            </AboutOL>
          </AboutLI>
          <AboutLI>
            Languages View
            <AboutOL>
              <AboutLI>
                Shapes representing approximate range of language use
              </AboutLI>
              <AboutLI>
                Tooltip information for each language (no. of speakers, source,
                etc.)
              </AboutLI>
            </AboutOL>
          </AboutLI>
          <AboutLI>Sources View</AboutLI>
          <AboutLI>
            Grammar View
            <AboutOL>
              <AboutLI>
                Morphological and syntactic reconstruction for Proto-Sumatran
              </AboutLI>
            </AboutOL>
          </AboutLI>
          <AboutLI>
            About View
            <AboutOL>
              <AboutLI>Integration with changelog file</AboutLI>
            </AboutOL>
          </AboutLI>
        </AboutUL>
      </AboutParagraph>
    </Container>
  );
};

export default About;

import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";

const Container = styled.div`
  width: 100%;
  margin: 0%;
  display: flex;
  flex-direction: column;
`;

const SourceTitle = styled.h2``;

const SourceInfoTable = styled.table`
  width: max-content;
  max-width: 100%;
`;

const SourceInfoTableRow = styled.tr``;

const SourceInfoTableHeader = styled.th`
  white-space: nowrap;
  vertical-align: top;
  text-align: left;
`;

const SourceInfoTableData = styled.td`
  vertical-align: top;
  text-align: left;
  padding: 0px 10px;
`;

const Source = (props) => {
  const headers = [
    "Citation",
    "Author",
    "Year",
    "Subject Language",
    "Analysis Language",
    "Source Type",
  ];

  const headersToFields = {
    Citation: "citation",
    Author: "author",
    Year: "year",
    "Subject Language": "subjectLanguage",
    "Analysis Language": "analysisLanguage",
    "Source Type": "sourceType",
  };

  return (
    <Container>
      {Object.keys(props).length === 0 ? (
        <SourceTitle>Unknown Source</SourceTitle>
      ) : (
        <React.Fragment>
          <SourceTitle>
            {props.title} (sourceID: {props.id})
          </SourceTitle>
          <SourceInfoTable>
            <tbody>
              {headers.map((header, index) => (
                <SourceInfoTableRow key={index}>
                  <SourceInfoTableHeader>{header}:</SourceInfoTableHeader>
                  <SourceInfoTableData>
                    {ReactHtmlParser(props[headersToFields[header]])}
                  </SourceInfoTableData>
                </SourceInfoTableRow>
              ))}
              <SourceInfoTableRow>
                <SourceInfoTableHeader>Description:</SourceInfoTableHeader>
                <SourceInfoTableData>
                  {props.hasOwnProperty("description") &&
                    props.description.map(({ header, paragraphs }, index) => (
                      <React.Fragment key={index}>
                        {index !== 0 && <br />}
                        <h3>{header}</h3>
                        {paragraphs.map((par, indexB) => (
                          <p key={indexB}>{ReactHtmlParser(par)}</p>
                        ))}
                      </React.Fragment>
                    ))}
                </SourceInfoTableData>
              </SourceInfoTableRow>
            </tbody>
          </SourceInfoTable>
        </React.Fragment>
      )}
    </Container>
  );
};

export default Source;

import styled from "styled-components";

const Container = styled.div`
  height: 30px;
  background-color: darkred;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: 14px;
  font-weight: 500;
`;

const FooterItalics = styled.i`
  color: white;
`;

const Footer = () => {
  return (
    <div>
      <Container>
        Citation: Billings, Blaine. 2023.{" "}
        <FooterItalics>
          Electronic handbook of Sumatran linguistics
        </FooterItalics>
        . (https://www.e-hsl.com/)
      </Container>
    </div>
  );
};

export default Footer;

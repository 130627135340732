import React, { useState } from "react";
import styled from "styled-components";
import {
  ArrowDropDown,
  ArrowDropUp,
  Share,
  SortByAlpha,
} from "@mui/icons-material";

const TableWrapper = styled.div`
  padding: 2px;
  border-width: 2px;
  border-style: solid;
  border-color: darkgray;
  border-radius: 6px;
  background-color: white;
  overflow-x: scroll;
  height: 80vh;
  // max-height: 80vh;
`;

const TableOptions = styled.div`
  position: sticky;
  left: 20px;
  display: flex;
  flex-direction: column;
  margin: 20px 20px 0px 20px;
  flex: 1;
`;

const TableOptionsHeader = styled.div`
  text-align: center;
  font-size: 20px;
`;

const TableOptionsFields = styled.div`
  width: 100%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 150px));
  text-align: left;
`;

const TableOptionsField = styled.label`
  flex: 1;
  white-space: nowrap;
  display: ${(props) => props.visible === false && "none"};
`;

const TableInfo = styled.div`
  padding: 5px;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;
`;

const TableBody = styled.tbody``;

const TableHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TableHeader = styled.th`
  padding: 2px 4px;
  display: ${(props) => props.visible === false && "none"};
  white-space: nowrap;
  max-width: 15vw;
  background-color: white;
  border-bottom: 2px solid black;
`;

const TableHeaderText = styled.span`
  padding: 0px 4px 0px 0px;
  float: left;
`;

const TableHeaderSort = styled.span`
  cursor: pointer;
  padding: 0px 0px 0px 4px;
  float: right;
`;

const TableSearch = styled.input`
  width: 100%;
  padding: 0px;
  margin: 0px;
`;

const TableRow = styled.tr`
  background-color: ${(props) =>
    props.rowIndex % 2 === 1 ? "#ffffff" : "#e7e7e7"};
`;

const TableData = styled.td`
  padding: 2px 4px;
  display: ${(props) => props.visible === false && "none"};
  text-align: ${(props) => (props.align === "center" ? "center" : "left")};
  max-width: 15vw;
`;

const TableDataDiv = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  //  white-space: nowrap;
  //  overflow: scroll;
`;

const WorldcatLink = styled.a``;

const SCDTable = (props) => {
  const determineHeaders = (entry) => {
    let newHeaders = props.headers
      .filter((header) => entry.hasOwnProperty(props.headersToFields[header]))
      .map((header) =>
        props.type === "entriesTable" ? entry.language + "-" + header : header
      );
    if (props.type === "entriesTable") {
      entry.children.forEach(
        (child) => (newHeaders = newHeaders.concat(determineHeaders(child)))
      );
    }
    return newHeaders;
  };
  const foundHeaders = determineHeaders(
    props.data.length > 0 ? props.data[0] : {}
  );

  const [headersSorts, setHeadersSorts] = useState(
    foundHeaders.reduce((accumulator, header) => {
      return { ...accumulator, [header]: 0 };
    }, {})
  );
  const [headersSearches, setHeadersSearches] = useState(
    foundHeaders.reduce((accumulator, header) => {
      return { ...accumulator, [header]: "" };
    }, {})
  );
  const [subheadersViews, setSubheadersViews] = useState(
    foundHeaders.reduce((accumulator, header) => {
      return { ...accumulator, [header]: true };
    }, {})
  );

  const clickSort = (clickedHeader) => {
    let newSorts = foundHeaders.reduce((accumulator, header) => {
      return { ...accumulator, [header]: 0 };
    }, {});
    newSorts[clickedHeader] =
      headersSorts[clickedHeader] === 2 ? 0 : headersSorts[clickedHeader] + 1;
    setHeadersSorts({ ...newSorts });
  };

  const getIcon = (header) => {
    return headersSorts[header] === 0 ? (
      <SortByAlpha fontSize="small" />
    ) : headersSorts[header] === 1 ? (
      <ArrowDropUp fontSize="small" />
    ) : (
      <ArrowDropDown fontSize="small" />
    );
  };

  // Used when searching in the entries table: identify the language
  // entry that is being searched on based on the "language" of the
  // column being searched
  const identifyTierSubLangEntry = (entry, language) => {
    if (entry["language"] === language) {
      return entry;
    } else if (entry.hasOwnProperty("children")) {
      for (let e in entry["children"]) {
        let x = identifyTierSubLangEntry(entry["children"][e], language);
        if (x) {
          return x;
        }
      }
    } else {
      console.log("Language subentry not found.");
      return false;
    }
  };

  const filterData = (entry) => {
    let checked = Object.keys(headersSearches).reduce((accumulator, header) => {
      if (headersSearches[header] === "") {
        return accumulator;
      } else if (props.type === "entriesTable") {
        let split_header = header.split("-");
        let language = split_header[0];
        let header_field = split_header[1];
        let ident_entry = identifyTierSubLangEntry(entry, language);
        try {
          let pattern = new RegExp(headersSearches[header], "i");
          return (
            accumulator &&
            (!ident_entry.hasOwnProperty(props.headersToFields[header_field]) ||
              pattern.test(ident_entry[props.headersToFields[header_field]]))
          );
        } catch (e) {
          return accumulator;
        }
      } else {
        try {
          let pattern = new RegExp(headersSearches[header], "i");
          return (
            accumulator &&
            (!entry.hasOwnProperty(props.headersToFields[header]) ||
              pattern.test(entry[props.headersToFields[header]]))
          );
        } catch (e) {
          return accumulator;
        }
      }
    }, true);
    return checked;
  };

  const sortData = (entry1, entry2) => {
    let sortingRules = Object.keys(headersSorts).reduce(
      (accumulator, header) => {
        if (headersSorts[header]) {
          accumulator["header"] = header;
          accumulator["value"] = headersSorts[header];
        }
        return accumulator;
      },
      { header: "", value: 0 }
    );
    if (sortingRules["header"] !== "" && props.type === "entriesTable") {
      if (entry1.language !== sortingRules.header.split("-")[0]) {
        return entry1.children.reduce(
          (accumulator, _, index) =>
            accumulator +
            sortData(entry1.children[index], entry2.children[index]),
          0
        );
      } else {
        if (sortingRules["value"] === 1) {
          return (
            entry1[props.headersToFields[sortingRules.header.split("-")[1]]] >
            entry2[props.headersToFields[sortingRules.header.split("-")[1]]]
          );
        } else {
          return (
            entry1[props.headersToFields[sortingRules.header.split("-")[1]]] <
            entry2[props.headersToFields[sortingRules.header.split("-")[1]]]
          );
        }
      }
    } else if (sortingRules["header"] !== "" && props.type === "sourcesTable") {
      if (sortingRules["value"] === 1) {
        return (
          entry1[props.headersToFields[sortingRules["header"]]] >
          entry2[props.headersToFields[sortingRules["header"]]]
        );
      } else {
        return (
          entry1[props.headersToFields[sortingRules["header"]]] <
          entry2[props.headersToFields[sortingRules["header"]]]
        );
      }
    } else {
      return 0;
    }
  };

  const collectHeaders = (entry, index) => {
    return (
      <React.Fragment key={index}>
        {props.headers
          .filter((header) =>
            entry.hasOwnProperty(props.headersToFields[header])
          )
          .map((header) => (
            <TableHeader
              key={header}
              visible={
                props.headersViews[header] &&
                (props.type !== "entriesTable" ||
                  subheadersViews[entry.language + "-" + header])
              }
            >
              <TableHeaderWrapper>
                <TableHeaderText>
                  {(props.type === "entriesTable" ? entry.language + "-" : "") +
                    header}
                </TableHeaderText>
                <TableHeaderSort
                  onClick={() =>
                    clickSort(
                      (props.type === "entriesTable"
                        ? entry.language + "-"
                        : "") + header
                    )
                  }
                >
                  {getIcon(
                    (props.type === "entriesTable"
                      ? entry.language + "-"
                      : "") + header
                  )}
                </TableHeaderSort>
              </TableHeaderWrapper>
            </TableHeader>
          ))}
        {props.type === "entriesTable" &&
          entry.children.map((innerEntry, index) =>
            collectHeaders(innerEntry, index)
          )}
      </React.Fragment>
    );
  };

  const collectSearchRow = (entry, index) => {
    return (
      <React.Fragment key={index}>
        {props.headers
          .filter((header) =>
            entry.hasOwnProperty(props.headersToFields[header])
          )
          .map((header) => (
            <TableData
              key={header}
              visible={
                props.headersViews[header] &&
                (props.type !== "entriesTable" ||
                  subheadersViews[entry.language + "-" + header])
              }
            >
              <TableSearch
                onChange={(event) =>
                  setHeadersSearches({
                    ...headersSearches,
                    [(props.type === "entriesTable"
                      ? entry.language + "-"
                      : "") + header]: event.target.value.toLowerCase(),
                  })
                }
                placeholder={"Search " + header}
              />
            </TableData>
          ))}
        {props.type === "entriesTable" &&
          entry.children.map((innerEntry, index) =>
            collectSearchRow(innerEntry, index)
          )}
      </React.Fragment>
    );
  };

  const collectData = (entry, index) => {
    return (
      <React.Fragment key={index}>
        {props.headers
          .filter((header) =>
            entry.hasOwnProperty(props.headersToFields[header])
          )
          .map((header, index) => (
            <TableData
              key={index}
              visible={
                props.headersViews[header] &&
                (props.type !== "entriesTable" ||
                  subheadersViews[entry.language + "-" + header])
              }
              align={header === "Worldcat" && "center"}
            >
              <TableDataDiv>
                {header === "Worldcat" ? (
                  entry[props.headersToFields[header]] === "NA" ? (
                    "NA"
                  ) : (
                    <WorldcatLink
                      target="_blank"
                      rel="noopener noreferrer"
                      href={entry[props.headersToFields[header]]}
                    >
                      <Share style={{ fill: "blue" }} />
                    </WorldcatLink>
                  )
                ) : (
                  entry[props.headersToFields[header]]
                    .split(";")
                    .map((data, index) => (
                      <React.Fragment key={index}>
                        {index !== 0 && <br />}
                        {props.type === "sourcesTable" &&
                        props.headersToFields[header] === "id" ? (
                          <span
                            onClick={() => props.setSource(data)}
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {data}
                          </span>
                        ) : (
                          <span>{data} </span>
                        )}
                      </React.Fragment>
                    ))
                )}
              </TableDataDiv>
            </TableData>
          ))}
        {props.type === "entriesTable" &&
          entry.children.map((innerEntry, index) =>
            collectData(innerEntry, index)
          )}
      </React.Fragment>
    );
  };

  const getInput = (header) => {
    return (
      <input
        type="checkbox"
        checked={subheadersViews[header]}
        onChange={() => {
          setSubheadersViews({
            ...subheadersViews,
            [header]: !subheadersViews[header],
          });
        }}
      />
    );
  };

  return (
    <TableWrapper>
      {props.type === "entriesTable" && (
        <TableOptions>
          <TableOptionsHeader>Options</TableOptionsHeader>
          <TableOptionsFields>
            {Object.keys(subheadersViews).map((header) => (
              <TableOptionsField
                visible={props.headersViews[header.split("-")[1]]}
              >
                {getInput(header)}View {header}
              </TableOptionsField>
            ))}
          </TableOptionsFields>
        </TableOptions>
      )}
      <TableInfo>
        {props.data.filter((entry) => filterData(entry)).length} Entries
      </TableInfo>
      <Table>
        <TableHead>
          <TableRow rowIndex={1}>{collectHeaders(props.data[0], 1)}</TableRow>
          <TableRow rowIndex={1}>{collectSearchRow(props.data[0], 1)}</TableRow>
        </TableHead>
        <TableBody>
          {props.data
            .filter((entry) => filterData(entry))
            //            .slice(0, 100)
            .sort((entry1, entry2) => sortData(entry1, entry2))
            .map((entry, index) => (
              <TableRow rowIndex={index} key={index}>
                {collectData(entry, index)}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

export default SCDTable;

import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { hierarchy } from "../hierarchy";

const Container = styled.div`
  width: 80%;
  margin: 5% 10% 10% 10%;
`;

const Wrapper = styled.div`
  //  border-width: 2px;
  //  border-style: solid;
  //  border-color: darkgray;
  //  background-color: #fdfce5;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 20px 0px 20px;
`;

const OptionsHeader = styled.div`
  text-align: center;
  font-size: 20px;
`;

const OptionsFields = styled.div`
  display: flex;
  text-align: center;
`;

const OptionsField = styled.label`
  flex: 1;
`;

const AlphabetNavigation = styled.div`
  margin: 0px 20px;
  display: flex;
  justify-content: space-between;
`;

const NavPrevious = styled.button`
  text-align: left;
`;

const NavNext = styled.button`
  text-align: right;
  justify-content: flex-end;
`;

const AlphabetHeader = styled.h2`
  text-align: center;
  margin: 20px 20px 0px 20px;
`;

const AlphabetLink = styled.a`
  margin: 0px 5px;
  font-style: normal;
  text-decoration: none;
`;

const LetterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LetterHeader = styled.h1`
  text-align: center;
`;

const Letter = styled.a`
  font-size: 32px;
  margin: 10px 0px;
  text-align: center;
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
`;

const Entry = styled.div`
  margin: 0px 20px 0px 20px;
  height: 100%;
`;

const Tier1Wrapper = styled.div``;

const Tier1Header = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const Tier1Entry = styled.span`
  font-size: 20px;
`;

/**
const LangEntriesTable = styled.table`
  font-size: 16px;
  margin-left: 24px;
  table-layout: auto;
`;

const LangEntriesTableBody = styled.tbody``;

const LangEntry = styled.tr``;

const LangName = styled.th`
  font-weight: normal;
  text-align: left;
  vertical-align: top;
  padding: 0px 10px 0px 0px;
`;

const LangForm = styled.td`
  font-style: italic;
  text-align: left;
  vertical-align: top;
  padding: 0px 10px 0px 0px;
`;

const LangDef = styled.td`
  text-align: left;
  vertical-align: top;
`;
*/

const customDict = "aəiuptkbdgcjshlrmnŋɲwy?";
//const customDict = "abcdeəghjklmnɲŋoprstuwy";

const Hierarchy = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const LanguageLevel = styled.ul`
  list-style-type: none;
  margin-left: 0;
  padding-left: 30px;
`;

const LanguageContainer = styled.li`
  border-left: 1px solid black;
  &:last-of-type {
    border-left: 0px;
    & > div {
      border-width: 0 0 1px 1px;
    }
  }
`;

const Language = styled.div`
  width: fit-content;
  text-decoration: none;
  border: 1px solid #000;
  border-width: 0 0 1px 0px;
  border-radius: 0 0 0 10px;
  font-weight: ${(props) => props.filtering && "bold"};
`;

const Dict = () => {
  const [alphabetIndex, setAlphabetIndex] = useState(0);
  const options = {
    showRoot: "Display roots",
    singleLetter: "Show only one letter",
  };
  const [optionsValues, setOptions] = useState(
    Object.keys(options).reduce((accumulator, header) => {
      return { ...accumulator, [header]: false };
    }, {})
  );

  const compareStrings = (a, b) => {
    a = a.replaceAll("*", "").replaceAll("-", "");
    b = b.replaceAll("*", "").replaceAll("-", "");
    let diffs = a.split("").map((char, index) => {
      return index >= b.length
        ? -1
        : char === b.charAt(index)
        ? 0
        : customDict.indexOf(char) < customDict.indexOf(b.charAt(index))
        ? 1
        : -1;
    });
    let c = diffs.includes(1) ? diffs.indexOf(1) : diffs.length;
    let d = diffs.includes(-1) ? diffs.indexOf(-1) : diffs.length;
    return c - d;
  };

  const getHierarchy = (lang) => {
    return (
      <LanguageContainer key={lang.entry}>
        <Language>{lang.language}</Language>

        {lang.children.length !== 0 && (
          <LanguageLevel>
            {lang.children.map((innerLanguage, index) => (
              <React.Fragment key={index}>
                {getHierarchy(innerLanguage)}
              </React.Fragment>
            ))}
          </LanguageLevel>
        )}
      </LanguageContainer>
    );
  };

  const getOptionInput = (option, text) => {
    return (
      <OptionsField>
        <input
          type="checkbox"
          checked={optionsValues[options]}
          onChange={() => {
            setOptions({
              ...optionsValues,
              [option]: !optionsValues[option],
            });
          }}
        />
        {text}
      </OptionsField>
    );
  };

  const getAlphabetNavigation = () => {
    return (
      <AlphabetNavigation>
        <NavPrevious
          onClick={() =>
            setAlphabetIndex(
              alphabetIndex === 0 ? customDict.length - 1 : alphabetIndex - 1
            )
          }
        >
          ← Previous
        </NavPrevious>
        <NavNext
          onClick={() =>
            setAlphabetIndex(
              alphabetIndex + 1 === customDict.length ? 0 : alphabetIndex + 1
            )
          }
        >
          → Next
        </NavNext>
      </AlphabetNavigation>
    );
  };

  return (
    <Container>
      <Wrapper id="top">
        <Hierarchy>
          <LanguageLevel>
            {hierarchy.map((lang) => getHierarchy(lang))}
          </LanguageLevel>
        </Hierarchy>
        <Options>
          <OptionsHeader>Options</OptionsHeader>
          <OptionsFields>
            {Object.entries(options).map(([option, text]) =>
              getOptionInput(option, text)
            )}
          </OptionsFields>
        </Options>
        {customDict
          .split("")
          .filter(
            (_, index) =>
              !optionsValues["singleLetter"] ||
              (optionsValues["singleLetter"] && index === alphabetIndex)
          )
          .map((char, index) => (
            <LetterWrapper key={char + String(index)}>
              {optionsValues["singleLetter"] && getAlphabetNavigation()}
              <AlphabetHeader>
                <AlphabetLink href={"#top"}>(top)</AlphabetLink>
                {customDict.split("").map((char, index) => (
                  <AlphabetLink
                    href={"#" + char}
                    onClick={() => {
                      setAlphabetIndex(customDict.indexOf(char));
                    }}
                    key={char + String(index)}
                  >
                    {char}
                  </AlphabetLink>
                ))}
              </AlphabetHeader>
              <LetterHeader>
                <Letter id={char}>*{char}</Letter>
              </LetterHeader>
              {/*protoBatak
                .map((entry) => entry.children[0])
                .filter((entry) =>
                  char === "?"
                    ? !customDict.includes(entry.entry.charAt(1))
                    : entry.entry.startsWith("*" + char)
                )
                .sort((entry1, entry2) =>
                  compareStrings(entry1.entry, entry2.entry)
                )
                .map((entry, index) => (
                  <Entry key={entry.entry + String(index)}>
                    <Tier1Wrapper>
                      <Tier1Header>
                        {entry.entry}
                        {optionsValues["showRoot"] &&
                          " (" + entry.root + ")"}:{" "}
                      </Tier1Header>
                      <Tier1Entry>{entry.def}</Tier1Entry>
                    </Tier1Wrapper>
                    {/*
                    <LangEntriesTable>
                      <LangEntriesTableBody>
                        {true &&
                          entry.children.map(
                            (childEntry, index) =>
                              childEntry.entry !== "" && (
                                <LangEntry
                                  key={childEntry.entry + String(index)}
                                >
                                  <LangName>{childEntry.language}:</LangName>
                                  <LangForm>{childEntry.entry}</LangForm>
                                  <LangDef>{childEntry.def}</LangDef>
                                </LangEntry>
                              )
                          )}
                      </LangEntriesTableBody>
                    </LangEntriesTable>
                              }
                  </Entry>
                ))*/}
            </LetterWrapper>
          ))}
      </Wrapper>
    </Container>
  );
};

export default Dict;

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`;

const Logo = styled.div`
  border-width: 2px 2px 2px 0px;
  border-style: solid;
  border-color: white;
  padding: 0px 25px;
  background-color: teal;
  font-size: 32px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  text-align: center;
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LanguageWrapper = styled.div`
  margin: 4px;
`;

const Language = styled.div`
  border: 2px solid gray;
  padding: 4px 8px;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  float: right;
  cursor: pointer;
`;

const SearchWrapper = styled.div`
  margin: 4px;
`;

const Search = styled.input`
  float: right;
`;

const NavigationWrapper = styled.nav`
  margin: 4px 0px 4px 4px;
  align-items: center;
`;

const NavigationList = styled.ul`
  list-style-type: none;
  float: right;
  display: flex;
  flex-wrap: wrap;
`;

const NavigationItem = styled.li`
  border-width: 2px 2px 2px 2px;
  border-style: solid;
  border-color: white;
  flex: 1;
`;

const NavigationLink = styled(Link)`
  padding: 0.5em 1em;
  background-color: #c0c0c0;
  &:hover {
    background-color: #808080;
    transition-duration: 200ms;
  }
  text-align: center;
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 16px;
  display: block;
`;

const SiteTitleContainer = styled.div`
  width: 100%;
`;

const SiteTitle = styled(Link)`
  padding: 10px 10px;
  font-size: 24px;
  text-decoration: none;
  display: block;
  color: white;
  background-color: teal;
  text-align: left;
  font-weight: bold;
`;

const Navbar = () => {
  return (
    <Container>
      <Header>
        <Logo>eHSL</Logo>
        <NavigationContainer>
          <LanguageWrapper>
            <Language>EN</Language>
          </LanguageWrapper>
          <SearchWrapper>
            <Search placeholder="Search e-HSL" />
          </SearchWrapper>
          <NavigationWrapper>
            <NavigationList>
              <NavigationItem>
                <NavigationLink to="/">Home</NavigationLink>
              </NavigationItem>
              <NavigationItem>
                <NavigationLink to="/dict">Dictionary</NavigationLink>
              </NavigationItem>
              <NavigationItem>
                <NavigationLink to="/entries">Entries</NavigationLink>
              </NavigationItem>
              <NavigationItem>
                <NavigationLink to="/languages">Languages</NavigationLink>
              </NavigationItem>
              <NavigationItem>
                <NavigationLink to="/sources">Sources</NavigationLink>
              </NavigationItem>
              <NavigationItem>
                <NavigationLink to="/about">About</NavigationLink>
              </NavigationItem>
            </NavigationList>
          </NavigationWrapper>
        </NavigationContainer>
      </Header>
      <SiteTitleContainer>
        <SiteTitle to="/">e-Handbook of Sumatran Linguistics</SiteTitle>
      </SiteTitleContainer>
    </Container>
  );
};

export default Navbar;

export const sources = [
  // Gayo
  {
    sourceType: "dictionary",
    title: "Gajōsch-Nederlandsch Woordenboek met Nederlandsch-Gajōsch Register",
    year: "1907",
    author: "G. A. J. Hazeu",
    description: [
      {
        header: "Description",
        paragraphs: ["A Gayo-Dutch dictionary."],
      },
    ],
    id: "Hazeu 1907",
    worldcat: "https://worldcat.org/en/title/9769365",
    subjectLanguage: "Gayo",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "grammar",
    title: "A Grammar of Gayo: A Language of Aceh, Sumatra",
    year: "2005",
    author: "Domenyk Eades",
    description: [
      {
        header: "Description",
        paragraphs: ["A grammar of Gayo."],
      },
    ],
    id: "Eades 2005",
    worldcat: "https://worldcat.org/en/title/61689497",
    subjectLanguage: "Gayo",
    analysisLanguage: "English",
  },
  {
    sourceType: "ethnogrpahy",
    title: "Atjèh Algemeen Samenvattend Overzicht van Land en Volk van Atjèh en Onderhoorigheden, Vol. 1",
    year: "1922",
    author: "J. Kreemer",
    description: [
      {
        header: "Description",
        paragraphs: ["An ethnography of the inhabitants of Ache with lexical data primarily on Acehnese, Gayo, and Alas."],
      },
    ],
    id: "Kreemer 1922",
    worldcat: "https://search.worldcat.org/title/7480376",
    subjectLanguage: "Acehnese, Gayo, Alas",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "ethnogrpahy",
    title: "Atjèh Algemeen Samenvattend Overzicht van Land en Volk van Atjèh en Onderhoorigheden, Vol. 2",
    year: "1923",
    author: "J. Kreemer",
    description: [
      {
        header: "Description",
        paragraphs: ["An ethnography of the inhabitants of Ache with lexical data primarily on Acehnese, Gayo, and Alas."],
      },
    ],
    id: "Kreemer 1923",
    worldcat: "https://search.worldcat.org/title/7480376",
    subjectLanguage: "Acehnese, Gayo, Alas",
    analysisLanguage: "Dutch",
  },
  // Alas
  {
    sourceType: "dictionary",
    title: "Kamus Alas-Indonesia",
    year: "1985",
    author: "Osra M. Akbar & Syech Ahmaddin & Hasanuddin Mardjo",
    citation:
      "Akbar, Osra M., Syech Ahmaddin, & Hasanuddin Mardjo. 1985. <em>Kamus Alas-Indonesia</em>. Jakarta: Pusat Pembinaan dan Pengembangan Bahasa.",
    description: [
      {
        header: "Description",
        paragraphs: [
          "The most thorough dictionary for the Alas language. It opens with a brief morphological sketech and a brief phonological sketch before beginning the body of the text. In total, it contains ~7,000 entries. There are numerous spelling errors in both the Alas entries and the Indonesian definitions, but nearly all of them are very minor. These are remarked where it was noticed and fixed where it is clear what they should be. Many of the errors in Alas come from the distinction between &lt;e&gt; [ə] and &lt;é&gt; [e] where one was seemingly exchanged for the other.",
        ],
      },
      {
        header: "Orthography → Phonology Changes",
        paragraphs: [
          "In order to convert the orthography into a phonological representation, the following changes were made:",
          " • &lt;e&gt; → /ə/",
          " • &lt;é&gt; → /e/",
          " • &lt;R&gt; → /ʀ/",
          " • &lt;ng&gt; → /ŋ/",
          " • &lt;ny&gt; → /ɲ/",
        ],
      },
    ],
    id: "Akbar et al. 1985",
    worldcat: "https://worldcat.org/en/title/15207996",
    subjectLanguage: "Alas",
    analysisLanguage: "Indonesian",
  },
  {
    sourceType: "grammar",
    title: "The Alas Language (Northern Sumatra)",
    year: "2007",
    author: "Giulio Soravia",
    citation:
      "Soravia, Giulio. 2007. <em>A grammar of Alas</em>. Bologna: University of Bologna.",
    description: [
      {
        header: "Description",
        paragraphs: ["The most thorough grammar for the Alas language. "],
      },
    ],
    id: "Soravia 2007",
    worldcat: "https://worldcat.org/en/title/1088812147",
    subjectLanguage: "Alas",
    analysisLanguage: "English",
  },
  // Singkil
  {
    sourceType: "ethnography",
    citation:
      "von Rosenberg, H. 1855. Geografisch ethnografische beschrijving van het distrikt Singkel de landen liggende langs de Simpang Kanan en de Banjak-Eilanden. <em>Tijdschrift voor Indische Taal-, Land- en Volkenkunde</em> 3. 397–476.",
    title:
      "Geografisch ethnografische beschrijving van het distrikt Singkel de landen liggende langs de Simpang Kanan en de Banjak-Eilanden",
    year: "1855",
    author: "H. von Rosenberg",
    description: [
      {
        header: "Description",
        paragraphs: [
          "A Dutch ethnography of Singkil. Includes a Dutch-Acehnese-Singkil-Dairi-Toba-Haloban comparative wordlist.",
        ],
      },
    ],
    id: "von Rosenberg 1885b",
    worldcat: "https://worldcat.org/en/title/1607509",
    subjectLanguage: "Singkil, Dairi, Toba, Haloban, Acehnese",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "dictionary",
    title: "Nanggakh Basa Singkil",
    year: "2016",
    author: "H. Mu'adz Vohry",
    description: [
      {
        header: "Description",
        paragraphs: ["A Singkil-Indonesian dictionary."],
      },
    ],
    id: "Vohry 2016",
    worldcat: "NA",
    subjectLanguage: "Singkil",
    analysisLanguage: "Indonesian",
  },
  {
    sourceType: "dictionary",
    title: "Kamus Umum Basa Singkil",
    year: "2012",
    author: "H. Salman AM & Mulyadi Kombih",
    description: [
      {
        header: "Description",
        paragraphs: ["A Singkil-Indonesian dictionary."],
      },
    ],
    id: "Salman & Kombih 2012",
    worldcat: "NA",
    subjectLanguage: "Singkil",
    analysisLanguage: "Indonesian",
  },
  // Pakpak/Dairi
  {
    sourceType: "dictionary",
    title: "Kamus Pakpak-Indonesia",
    year: "2002",
    author: "Tindi Radja Manik",
    description: [
      {
        header: "Description",
        paragraphs: ["A Dairi-Indonesian dictionary."],
      },
    ],
    id: "Manik 2002",
    worldcat: "https://worldcat.org/en/title/52593498",
    subjectLanguage: "Dairi",
    analysisLanguage: "Indonesian",
  },
  // Karo
  {
    sourceType: "grammar",
    citation:
      "Joustra, M. 1906. Karo'sche taalstudien. <em>Bijdragen tot de Taal-, Land-, en Volkenkunde van Nederlandsch-Indië</em> 59. 562–590.",
    title: "Karo'sche Taalstudien",
    year: "1906",
    author: "M. Joustra",
    description: [
      {
        header: "Description",
        paragraphs: ["A Dutch description of Karo grammar."],
      },
    ],
    id: "Joustra 1906",
    worldcat: "https://worldcat.org/en/title/1114306552",
    subjectLanguage: "Karo",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "dictionary",
    title: "Karo-Bataksch Woordenboek",
    year: "1907",
    author: "M. Joustra",
    description: [
      {
        header: "Description",
        paragraphs: ["A Karo-Dutch dictionary."],
      },
    ],
    id: "Joustra 1907",
    worldcat: "https://worldcat.org/en/title/55481122",
    subjectLanguage: "Karo",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "dictionary",
    title: "Nederlandsch-Karosche Woordenlijst",
    year: "1922",
    author: "M. Joustra",
    description: [
      {
        header: "Description",
        paragraphs: ["A Karo-Dutch wordlist."],
      },
    ],
    id: "Joustra 1922",
    worldcat: "https://worldcat.org/en/title/882977238",
    subjectLanguage: "Karo",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "grammar",
    title: "Schets der Karo-Bataksche Spraakkunst",
    year: "1922",
    author: "J. H. Neumann",
    description: [
      {
        header: "Description",
        paragraphs: ["A grammar of Karo."],
      },
    ],
    id: "Neumann 1922",
    worldcat: "https://worldcat.org/en/title/14055330",
    subjectLanguage: "Karo",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "dictionary",
    title: "Karo Bataks-Nederlands Woordenboek",
    year: "1951",
    author: "J. H. Neumann",
    description: [
      {
        header: "Description",
        paragraphs: ["A Karo-Dutch dictionary."],
      },
    ],
    id: "Neumann 1951",
    worldcat: "https://worldcat.org/en/title/12208579",
    subjectLanguage: "Karo",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "grammar",
    title: "Bahasa Karo",
    year: "1979",
    author: "Henry Guntur Tarigan & Djago Tarigan",
    description: [
      {
        header: "Description",
        paragraphs: ["A Karo grammar."],
      },
    ],
    id: "Tarigan & Tarigan 1979",
    worldcat: "https://worldcat.org/en/title/8182404",
    subjectLanguage: "Karo",
    analysisLanguage: "Indonesian",
  },
  {
    sourceType: "grammar",
    title: "A Grammar of Karo Batak, Sumatra",
    year: "1996",
    author: "Geoff Woollams",
    description: [
      {
        header: "Description",
        paragraphs: ["A Karo grammar."],
      },
    ],
    id: "Woollams 1996",
    worldcat: "https://worldcat.org/en/title/34750172",
    subjectLanguage: "Karo",
    analysisLanguage: "English",
  },
  {
    sourceType: "dictionary",
    title: "Kamus Bahasa Karo-Indonesia",
    year: "2001",
    author:
      "Ahmad Samin Siregar & Peraturen Sukapiring & Sentosa Tarigan & Matius Cikappen Sembiring & Zulkifly",
    description: [
      {
        header: "Description",
        paragraphs: ["A Karo-Indonesian dictionary."],
      },
    ],
    id: "Siregar et al. 2001",
    worldcat: "https://worldcat.org/en/title/48691772",
    subjectLanguage: "Karo",
    analysisLanguage: "Indonesian",
  },
  // Simalungun
  {
    sourceType: "dictionary",
    title: "Kamus Bahasa Simalungun-Indonesia",
    year: "2016",
    author: "Zufri Hidayat",
    description: [
      {
        header: "Description",
        paragraphs: [
          "A Simalungun-Indonesian dictionary, this remains the most thorough dictionary for Simalungun, and its inclusion of a great number of word forms under each root must be applauded. The dictionary opens with brief notes about spelling, dictionary format, abbreviations, and pronunciation. There are a number of errors throughout the text, but most are simple spelling errors - these are fixed and (remarked as such) where they are noticed. Where an inconsistency cannot be clearly resolved, this is remarked. The orthography (not phonetic pronunciation) is reproduced here with only a couple of changes: NC clusters are reproduced as CC where this is marked in the phonetic pronuncation, and <ng> is rendered <ŋ>.",
        ],
      },
    ],
    id: "Hidayat 2016",
    worldcat: "https://worldcat.org/en/title/986993978",
    subjectLanguage: "Simalungun",
    analysisLanguage: "Indonesian",
  },
  // Toba
  {
    sourceType: "wordlist",
    citation:
      "Raffles, Thomas S. n.d. A Toba wordlist. In <em>Mss Eur E108 Raffles Collection IX</em>, 232–247. London: British Library, India Office Records and Private Papers.",
    title: "A Toba wordlist",
    year: "n.d.",
    author: "Thomas Stamford Raffles",
    description: [
      {
        header: "Description",
        paragraphs: [
          "A Toba wordlist. A Minangkabau wordlist can be found in the same manuscript on pages 208–226.",
        ],
      },
    ],
    id: "Raffles n.d.c",
    worldcat: "NA",
    subjectLanguage: "Toba",
    analysisLanguage: "English",
  },
  {
    sourceType: "dictionary",
    title: "Bataksch-Nederduitsch Woordenboek",
    year: "1861",
    author: "H. N. van der Tuuk",
    description: [
      {
        header: "Description",
        paragraphs: ["A Batak-Dutch dictionary."],
      },
    ],
    id: "van der Tuuk 1861",
    worldcat: "https://worldcat.org/en/title/20080137",
    subjectLanguage: "Toba, Dairi, Mandailing, Angkola",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "dictionary",
    title: "Nederlandsch-Tobasche Woordenlijst",
    year: "1912",
    author: "Herm. W. Stap",
    description: [
      {
        header: "Description",
        paragraphs: ["A Toba-Dutch dictionary."],
      },
    ],
    id: "Stap 1912",
    worldcat: "https://worldcat.org/en/title/7535430",
    subjectLanguage: "Toba",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "dictionary",
    title: "Toba-Batak–Deutsches Wörterbuch",
    year: "1977",
    author: "Joh. Warneck",
    description: [
      {
        header: "Description",
        paragraphs: ["A Toba-German dictionary."],
      },
    ],
    id: "Warneck 1977",
    worldcat: "https://worldcat.org/en/title/5137242",
    subjectLanguage: "Toba",
    analysisLanguage: "German",
  },
  {
    sourceType: "grammar",
    citation:
      "van der Tuuk, H. N. 1971. <em>A grammar of Toba Batak</em> (Jeune Scott-Kemball, trans.). Berlin: Springer-Science+Business Media. (Original work published 1867.)",
    title: "A Grammar of Toba Batak",
    year: "1971",
    author: "H. N. van der Tuuk",
    description: [
      {
        header: "Description",
        paragraphs: ["A grammar of Toba."],
      },
    ],
    id: "van der Tuuk 1971",
    worldcat: "https://worldcat.org/en/title/212192",
    subjectLanguage: "Toba, Dairi, Mandailing, Angkola",
    analysisLanguage: "English",
  },
  {
    sourceType: "grammar",
    title: "A Grammar of Toba-Batak",
    year: "1981",
    author: "P. W. J. Nababan",
    description: [
      {
        header: "Description",
        paragraphs: ["A grammar of Toba."],
      },
    ],
    id: "Nababan 1981",
    worldcat: "https://worldcat.org/en/title/9942906",
    subjectLanguage: "Toba",
    analysisLanguage: "English",
  },
  {
    sourceType: "grammar",
    title: "A Grammar of the Urbanised Toba-Batak of Medan",
    year: "1981",
    author: "W. K. Percival",
    description: [
      {
        header: "Description",
        paragraphs: ["A grammar of Toba."],
      },
    ],
    id: "Percival 1981",
    worldcat: "https://worldcat.org/en/title/9083808",
    subjectLanguage: "Toba",
    analysisLanguage: "English",
  },
  {
    sourceType: "dictionary",
    citation:
      "Stokhof, W. A. L. 1986. <em>Holle lists: Vocabularies in languages of Indonesia</em>, vol. 9, <em>Northern Sumatra</em>. Canberra: The Australian National University.",
    title:
      "Holle Lists: Vocabularies in Langauges of Indonesia Volume 9 Northern Sumatra",
    year: "1986",
    author: "W. A. L. Stokhof",
    description: [
      {
        header: "Description",
        paragraphs: [
          "One of a series of sources regarding wordlists of languages of Indonesia. Contains wordlists on different varieties of Toba, Angkola, Mandailing, Karo, Dairi, and Acehnese.",
        ],
      },
    ],
    id: "Stokhof 1986",
    worldcat: "https://worldcat.org/en/title/9687143",
    subjectLanguage: "Toba, Angkola, Mandailing, Karo, Dairi, Acehnese",
    analysisLanguage: "English, Dutch",
  },
  // Angkola
  {
    sourceType: "dictionary",
    title: "Angkola- en Mandailing Bataksch-Nederlandsch Woordenboek",
    year: "1936",
    author: "H. J. Eggink",
    description: [
      {
        header: "Description",
        paragraphs: ["An Angkola/Mandailing-Dutch dictionary."],
      },
    ],
    id: "Eggink 1936",
    worldcat: "https://worldcat.org/en/title/23263329",
    subjectLanguage: "Angkola, Mandailing",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "dictionary",
    title: "Kamus Angkola Mandailing-Indonesia",
    year: "2016",
    author:
      "Anharuddin Hutasuhut & Chairani Nasution & Sriasrianti & Zufri Hidayat",
    description: [
      {
        header: "Description",
        paragraphs: ["An Angkola/Mandailing-Indonesian dictionary."],
      },
    ],
    id: "Hutasuhut et al. 2016",
    worldcat: "https://worldcat.org/en/title/986789003",
    subjectLanguage: "Angkola, Mandailing",
    analysisLanguage: "Indonesian",
  },
  // Simeulue
  {
    sourceType: "dictionary",
    citation:
      "Stokhof, W. A. L. 1987. <em>Holle lists: Vocabularies in languages of Indonesia</em>, vol. 10/3, <em>Islands of the west coast of Sumatra</em>. Canberra: The Australian National University.",
    title:
      "Holle Lists: Vocabularies in Langauges of Indonesia Volume 10/3 Islands off the West Coast of Sumatra",
    year: "1987",
    author: "W. A. L. Stokhof",
    description: [
      {
        header: "Description",
        paragraphs: [
          "One of a series of sources regarding wordlists of languages of Indonesia. Contains wordlists on different varieties of Simeulue, Leukon, Sigulai, Nias, Mentawai, and Enggano.",
        ],
      },
    ],
    id: "Stokhof 1987b",
    worldcat: "https://worldcat.org/en/title/9687143",
    subjectLanguage: "Simeulue, Leukon, Sigulai, Nias, Mentawai, Enggano",
    analysisLanguage: "English, Dutch",
  },
  {
    sourceType: "dictionary",
    citation:
      "Westenenk, L. C. 1904. Simaloersch woordenlijstje. <em>Bijdragen tot de Taal-, Land- en Volkenkunde van Nederlandsch-Indië</em> 56. 302–310.",
    title: "Simaloersch woordenlijstje",
    year: "1904",
    author: "L. C. Westenenk",
    description: [
      {
        header: "Description",
        paragraphs: ["A Simeulue-Dutch wordlist."],
      },
    ],
    id: "Westenenk 1904",
    worldcat: "https://worldcat.org/en/title/1114306552",
    subjectLanguage: "Simeulue",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "dictionary",
    citation:
      "Kähler, Hans. 1961. <em>Simalur-Deutsches wörterbuch mit Deutsch-Simaluresischem wörterverzeichnis</em>. Berlin: Dietrich Reimer.",
    title:
      "Simalur-Deutsches Wörterbuch mit Deutsch-Simaluresischem Wörterverzeichnis",
    year: "1961",
    author: "Hans Kähler",
    description: [
      {
        header: "Description",
        paragraphs: ["A Simeulue-German dictionary."],
      },
    ],
    id: "Kähler 1961",
    worldcat: "https://worldcat.org/en/title/10989630",
    subjectLanguage: "Simeulue",
    analysisLanguage: "German",
  },
  // Leukon
  {
    sourceType: "dictionary",
    citation:
      "Candrasari, Ratri & Khalsiah. 2018. <em>Kamus Indonesia-Leukon</em>. Lhokseumawe: Sefa Bumi Persada.",
    title: "Kamus Indonesia-Leukon",
    year: "2018",
    author: "Ratri Candrasari & Khalsiah",
    description: [
      {
        header: "Description",
        paragraphs: ["A Leukon-Indonesian dictionary."],
      },
    ],
    id: "Candrasari & Khalsiah 2018",
    worldcat: "NA",
    subjectLanguage: "Leukon",
    analysisLanguage: "Indonesian",
  },
  // Haloban
  {
    sourceType: "dictionary",
    citation:
      "Tim Kamus Balai Bahasa Banda Aceh. 2013. <em>Kamus bahasa Haloban</em>. Banda Aceh: Balai Bahasa Banda Aceh.",
    title: "Kamus Bahasa Haloban",
    year: "2013",
    author: "Tim Kamus Balai Bahasa Banda Aceh",
    description: [
      {
        header: "Description",
        paragraphs: ["A Haloban-Indonesian dictionary."],
      },
    ],
    id: "Tim Kamus 2013",
    worldcat: "https://worldcat.org/en/title/958625563",
    subjectLanguage: "Haloban",
    analysisLanguage: "Indonesian",
  },
  // Nias
  {
    sourceType: "ethnography",
    citation:
      "Short notice concerning the island of Pulo Nias. 1822. <em>Malayan Miscellanies</em> 2(8).",
    title: "Short notice concerning the island of Pulo Nias",
    year: "1822",
    author: "?",
    description: [
      {
        header: "Description",
        paragraphs: [
          "An English account of the island of Nias. Includes a short comparative vocabulary of English, Nias, Batak (presumably Toba or Angkola on the basis of consonant correspondences), Bima, and Lampung.",
        ],
      },
    ],
    id: "Short notice 1822",
    worldcat: "https://worldcat.org/en/title/31766837",
    subjectLanguage: "Nias, Toba, Bima, Lampung",
    analysisLanguage: "English",
  },
  {
    sourceType: "dictionary",
    citation:
      "Thomas, J. W. & E. A. Taylor Weber. 1887. <em>Niasch-Maleisch-Nederlandsch woordenboek</em>. Batavia: Landsdrukkerij.",
    title: "Niasch-Maleisch-Nederlandsch Woordenboek",
    year: "1887",
    author: "J. W. Thomas & E. A. Taylor Weber",
    description: [
      {
        header: "Description",
        paragraphs: ["A Nias-Indonesian-Dutch dictionary."],
      },
    ],
    id: "Thomas & Taylor Weber 1887",
    worldcat: "https://worldcat.org/en/title/624380752",
    subjectLanguage: "Nias",
    analysisLanguage: "Dutch, Indonesian",
  },
  {
    sourceType: "ethnography",
    citation:
      "Modigliani, Elio. 1890. <em>Un viaggio a Nias</em>. Milan: Fratelli Treves.",
    title: "Un Viaggio a Nias",
    year: "1890",
    author: "Elio Modigliani",
    description: [
      {
        header: "Description",
        paragraphs: ["An Italian ethnography of Nias. Includes a wordlist."],
      },
    ],
    id: "Modigliani 1890",
    worldcat: "https://worldcat.org/en/title/7112290",
    subjectLanguage: "Nias",
    analysisLanguage: "Italian",
  },
  {
    sourceType: "dictionary",
    citation:
      "Sundermann, H. 1905. <em>Niassisch-Deutsches wörterbuch</em>. Moers: J. W. Spaarmann.",
    title: "Niassisch-Deutsches Wörterbuch",
    year: "1905",
    author: "H. Sundermann",
    description: [
      {
        header: "Description",
        paragraphs: ["A Nias-German dictionary."],
      },
    ],
    id: "Sundermann 1905",
    worldcat: "https://worldcat.org/en/title/903784357",
    subjectLanguage: "Nias",
    analysisLanguage: "German",
  },
  {
    sourceType: "grammar",
    citation:
      "Sundermann, H. 1913. <em>Niassische sprachlehre</em>. 's‑Gravenhage: Martinus Nijhoff.",
    title: "Niassische Sprachlehre",
    year: "1913",
    author: "H. Sundermann",
    description: [
      {
        header: "Description",
        paragraphs: ["A German grammar of Nias."],
      },
    ],
    id: "Sundermann 1913",
    worldcat: "https://worldcat.org/en/title/913707917",
    subjectLanguage: "Nias",
    analysisLanguage: "German",
  },
  {
    sourceType: "grammar",
    citation:
      "Brown, Lea. 2001. <em>A grammar of Nias Selatan</em>. Sydney: University of Sydney. (Doctoral dissertation.)",
    title: "A Grammar of Nias Selatan",
    year: "2001",
    author: "Lea Brown",
    description: [
      {
        header: "Description",
        paragraphs: ["An English grammar of Nias."],
      },
    ],
    id: "Brown 2001",
    worldcat: "https://worldcat.org/en/title/223325298",
    subjectLanguage: "Nias",
    analysisLanguage: "English",
  },
  // Sigulai
  {
    sourceType: "grammar",
    citation:
      "Kähler, Hans. 1955. Die Sichule-sprache auf der Insel Simalur an der westküste von Sumatra. <em>Afrika und Übersee</em> 27.",
    title:
      "Die Sichule-spraches auf der Insel Simalur an der westküste von Sumatra",
    year: "1955",
    author: "Hans Kähler",
    description: [
      {
        header: "Description",
        paragraphs: ["A German grammar of Sigulai."],
      },
    ],
    id: "Kähler 1955",
    worldcat: "https://worldcat.org/en/title/3417032",
    subjectLanguage: "Sigulai",
    analysisLanguage: "German",
  },
  {
    sourceType: "dictionary",
    citation:
      "Kähler, Hans. 1959. <em>Vergleichendes wörterverzeichnis der Sichule-sprache auf der Insel Simalur an der westküste von Sumatra</em>. Berlin: Dietrich Reimer.",
    title:
      "Vergleichendes wörterverzeichnis der Sichule-sprache auf der Insel Simalur an der westküste von Sumatra",
    year: "1959",
    author: "Hans Kähler",
    description: [
      {
        header: "Description",
        paragraphs: ["A Sigulai-German dictionary."],
      },
    ],
    id: "Kähler 1959",
    worldcat: "https://worldcat.org/en/title/3096841",
    subjectLanguage: "Sigulai",
    analysisLanguage: "German",
  },
  // Mentawai
  {
    sourceType: "wordlist",
    citation:
      "Raffles, Thomas S. n.d. An English-Mentawai-Nias-Buginese comparative wordlist. In <em>Mss Eur D199 Raffles Collection VIII</em>, 285–299. London: British Library, India Office Records and Private Papers.",
    title: "An English-Mentawai-Nias Comparative Wordlist",
    year: "n.d.",
    author: "Thomas Stamford Raffles",
    description: [
      {
        header: "Description",
        paragraphs: ["An English-Mentawai-Nias-Buginese comparative wordlist."],
      },
    ],
    id: "Raffles n.d.a",
    worldcat: "NA",
    subjectLanguage: "Mentawai, Nias, Buginese",
    analysisLanguage: "English",
  },
  // NOTE: Make sure to rename these sources to match their names for these languages
  // NOTE: Make sure to check the authors on these because they are sometimes signed by other people
  {
    sourceType: "wordlist",
    citation:
      "Raffles, Thomas S. 1820. An English-Indonesian-Mentawai-Nias-Toba-Buginese-Tongan comparative wordlist. In <em>Mss Eur F32 Raffles Collection VII</em>, 107–110. London: British Library, India Office Records and Private Papers.",
    title:
      "An English-Indonesian-Mentawai-Nias-Toba-Buginese-Tongan Comparative Wordlist",
    year: "1820",
    author: "Thomas Stamford Raffles",
    description: [
      {
        header: "Description",
        paragraphs: [
          "An English-Indonesian-Mentawai-Nias-Batak-Buginese-Tongan comparative wordlist.",
        ],
      },
    ],
    id: "Raffles 1820",
    worldcat: "NA",
    subjectLanguage: "Mentawai, Nias, Toba, Buginese, Tongan, Indonesian",
    analysisLanguage: "English",
  },
  {
    sourceType: "wordlist",
    citation:
      "Raffles, Thomas S. n.d. An English-Mentawai-Nias-Buginese-Serawai-Lampung-Rejang-Toba comparative wordlist. In <em>Mss Eur F33 Raffles Collection X</em>, 319–328. London: British Library, India Office Records and Private Papers.",
    title:
      "An English-Mentawai-Nias-Buginese-Serawai-Lampung-Rejang-Toba comparative wordlist",
    year: "n.d.",
    author: "Thomas Stamford Raffles",
    description: [
      {
        header: "Description",
        paragraphs: [
          "An English-Mentawai-Nias-Buginese-Serawai-Lampung-Rejang-Toba comparative wordlist. An English-Serawai-Lampung-Rejang comparative wordlist can be found in the same manuscript on pages 279–290 as well as what appears to be a Toba wordlist on pages 291–301.",
        ],
      },
    ],
    id: "Raffles n.d.b",
    worldcat: "NA",
    subjectLanguage: "Mentawai, Nias, Toba, Lampung, Rejang, Serawai, Buginese",
    analysisLanguage: "English",
  },
  {
    sourceType: "wordlist",
    citation:
      "Raffles, Thomas S. n.d. An English-Indonesian-Mentawai-Nias comparative wordlist of pronominal forms. In <em>Mss Eur E109 Raffles Collection XI</em>, 386–387. London: British Library, India Office Records and Private Papers.",
    title:
      "An English-Indonesian-Mentawai-Nias comparative wordlist of pronominal forms",
    year: "n.d.",
    author: "Thomas Stamford Raffles",
    description: [
      {
        header: "Description",
        paragraphs: [
          "An English-Indonesian-Mentawai-Nias comparative wordlist of pronominal forms. A Minangkabau wordlist can be found in the same manuscript on pages 369–381 as well as a list of Malay-Mentawai-English bird names on page 433.",
        ],
      },
    ],
    id: "Raffles n.d.d",
    worldcat: "NA",
    subjectLanguage: "Mentawai, Nias, Indonesian",
    analysisLanguage: "English",
  },
  {
    sourceType: "ethnography",
    citation:
      "von Rosenberg, H. 1853. De Mentawei-Eilanden en hunne bewoners. <em>Tijdschrift voor Indische Taal-, Land- en Volkenkunde</em> 1. 399–441.",
    title: "De Mentawei-Eilanden en hunne bewoners",
    year: "1853",
    author: "H. von Rosenberg",
    description: [
      {
        header: "Description",
        paragraphs: [
          "A Dutch ethnography of Mentawai. Includes a Mentawai wordlist and a Dutch-Indonesian-Lampung-Rejang-Batak-Acehnese-Nias-Mentawai-Enggano comparative wordlist of the numerals 1-10.",
        ],
      },
    ],
    id: "von Rosenberg 1853",
    worldcat: "https://worldcat.org/en/title/1607509",
    subjectLanguage:
      "Mentawai, Lampung, Rejang, Batak, Acehnese, Nias, Enggano, Indonesian",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "ethnography",
    citation:
      "Hinlopen, P. A. M. & P. Severijn. 1855. Verslag van een onderzoek der Poggi-Eilanden. <em>Tijdschrift voor Indische Taal-, Land- en Volkenkunde</em> 3. 319–337.",
    title: "Verslag van een onderzoek der Poggi-Eilanden",
    year: "1855",
    author: "P. A. M. Hinlopen & P. Severijn",
    description: [
      {
        header: "Description",
        paragraphs: [
          "A Dutch ethnography of Mentawai. Includes a short Mentawai wordlist of names of trees.",
        ],
      },
    ],
    id: "Hinlopen & Severijn 1855",
    worldcat: "https://worldcat.org/en/title/1607509",
    subjectLanguage: "Mentawai",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "grammar",
    citation:
      "Morris, Max. 1900. <em>Die Mentawai-sprache</em>. Berlin: Conrad Skopnik.",
    title: "Die Mentawai-Sprache",
    year: "1900",
    author: "Max Morris",
    description: [
      {
        header: "Description",
        paragraphs: ["A German grammar of Mentawai."],
      },
    ],
    id: "Morris 1900",
    worldcat: "https://worldcat.org/en/title/6642076",
    subjectLanguage: "Mentawai",
    analysisLanguage: "German",
  },
  {
    sourceType: "grammar",
    citation:
      "Adriani, N. 1928. Spraakkunstige schets van de taal der Mĕntawai-Eilanden. <em>Bijdragen tot de Taal-, Land- en Volkenkunde van Nederlandsch Indië</em> 84(1). 1–117.",
    title: "Spraakkunstige schets van de taal der Mĕntawai-Eilanden",
    year: "1928",
    author: "N. Adriani",
    description: [
      {
        header: "Description",
        paragraphs: ["A Dutch grammar of Mentawai."],
      },
    ],
    id: "Adriani 1928",
    worldcat: "https://worldcat.org/en/title/1114306552",
    subjectLanguage: "Mentawai",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "grammar",
    citation:
      "Lenggang, Zainuddin HR, Be Kim Hoa Nio, Mohammad Ansyar, Zainil, & Syofyan Adam. 1978. <em>Bahasa Mentawai</em>. Jakarta: Pusat Pembinaan dan Pengembangan Bahasa.",
    title: "Bahasa Mentawai",
    year: "1978",
    author:
      "Zainuddin HR Lenggang & Be Kim Hoa Nio & Mohammad Ansyar & Zainil & Syofyan Adam",
    description: [
      {
        header: "Description",
        paragraphs: ["An Indonesian grammar of Mentawai."],
      },
    ],
    id: "Lenggang et al. 1978",
    worldcat: "https://worldcat.org/en/title/18124735",
    subjectLanguage: "Mentawai",
    analysisLanguage: "Indonesian",
  },
  {
    sourceType: "dictionary",
    citation:
      "Anonymous. n.d. <em>Kamus Simalegi (Dialektwörterbuch für die sprache von Siberut/Mentawai)</em>. (Unpublished manuscript.)",
    title:
      "Kamus Simalegi (Dialektwörterbuch für die sprache von Siberut/Mentawai)",
    year: "n.d.",
    author: "Anonymous",
    description: [
      {
        header: "Description",
        paragraphs: [
          "A Mentawai-Indonesian-Italian dictionary with data from five varieties of Mentawai - Simalegi, Umum Mentawai, Sikabaluan, Terekan, Simatalu.",
        ],
      },
    ],
    id: "Kamus Simalegi",
    worldcat: "https://worldcat.org/en/title/905887128",
    subjectLanguage: "Mentawai",
    analysisLanguage: "Indonesian, Italian",
  },
  {
    sourceType: "dictionary",
    citation:
      "Khatib, H. Yusran. 1994. <em>Kamus dwibahasa Indonesia-Mentawai</em>. Padang: Pusat Pembinaan dan Pengembangan Bahasa.",
    title: "Kamus Dwibahasa Indonesia-Mentawai",
    year: "1994",
    author: "H. Yusran Khatib",
    description: [
      {
        header: "Description",
        paragraphs: ["A Mentawai-Indonesian dictionary."],
      },
    ],
    id: "Khatib 1994",
    worldcat: "NA",
    subjectLanguage: "Mentawai",
    analysisLanguage: "Indonesian",
  },
  // Enggano
  {
    sourceType: "dictionary",
    citation: "?",
    title: "Two vocabularies of the language of Enggano",
    year: "1852 (?)",
    author: "Brouwer (?) & von Rosenberg (?)",
    description: [
      {
        header: "Description",
        paragraphs: ["Two early wordlists of Enggano."],
      },
    ],
    id: "Brouwer & von Rosenberg 1852",
    worldcat: "NA",
    subjectLanguage: "Enggano",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "ethnography",
    citation:
      "Boewang, Djoeragan. 1854. Verslag omtrent het Eiland Engano. <em>Tijdschrift voor Indische Taal-, Land- en Volkenkunde</em> 2. 379–393.",
    title: "Verslag omtrent het Eiland Engano",
    year: "1854",
    author: "Djoeragan Boewang",
    description: [
      {
        header: "Description",
        paragraphs: [
          "A Dutch ethnography of Enggano. Includes a short wordlist of Enggano numerals.",
        ],
      },
    ],
    id: "Boewang 1854",
    worldcat: "https://worldcat.org/en/title/1607509",
    subjectLanguage: "Enggano",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "ethnography",
    citation:
      "van der Straaten, J. & P. Severijn. 1855. Verslag van een in 1854 bewerkstelligd onderzoek op het eiland Engano. <em>Tijdschrift voor Indische Taal-, Land- en Volkenkunde</em> 3. 338–369.",
    title:
      "Verslag van een in 1854 bewerkstelligd onderzoek op het eiland Engano",
    year: "1855",
    author: "J. van der Straaten & P. Severijn",
    description: [
      {
        header: "Description",
        paragraphs: [
          "A Dutch ethnography of Enggano. Includes two Enggano wordlists: one short one that lists names of trees and a longer one at the end of the text.",
        ],
      },
    ],
    id: "van der Straaten & Severijn 1885",
    worldcat: "https://worldcat.org/en/title/1607509",
    subjectLanguage: "Enggano",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "ethnography",
    citation:
      "von Rosenberg, H. 1855. Beschrijving van Engano en van deszelfs bewoners. <em>Tijdschrift voor Indische Taal-, Land- en Volkenkunde</em> 3. 370–386.",
    title: "Beschrijving van Engano en van deszelfs bewoners",
    year: "1855",
    author: "H. von Rosenberg",
    description: [
      {
        header: "Description",
        paragraphs: [
          "A Dutch ethnography of Enggano. Includes an Enggano wordlist.",
        ],
      },
    ],
    id: "von Rosenberg 1855a",
    worldcat: "https://worldcat.org/en/title/1607509",
    subjectLanguage: "Enggano",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "ethnography",
    citation:
      "Walland, J. 1864. Het eiland Engano. <em>Tijdschrift voor Indische Taal-, Land- en Volkenkunde</em> 14. 93–124.",
    title: "Het eiland Engano",
    year: "1864",
    author: "J. Walland",
    description: [
      {
        header: "Description",
        paragraphs: [
          "A Dutch ethnography of Enggano. Includes a wordlist of two varieties of Enggano.",
        ],
      },
    ],
    id: "Walland 1864",
    worldcat: "https://worldcat.org/en/title/1607509",
    subjectLanguage: "Enggano",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "dictionary",
    citation:
      "Oudemans, J. A. C. 1879. Woordenlijst van de talen van Enggano, Mentawei en Nias. <em>Tijdschrift voor Indische Taal-, Land- en Volkenkunde</em> 25. 484–488.",
    title: "Woordenlijst van de talen van Enggano, Mentawei en Nias",
    year: "1879",
    author: "J. A. C. Oudemans",
    description: [
      {
        header: "Description",
        paragraphs: [
          "A brief Dutch-Enggano-Mentawai-Nias comparative wordlist.",
        ],
      },
    ],
    id: "Oudemans 1879",
    worldcat: "https://worldcat.org/en/title/1607509",
    subjectLanguage: "Enggano, Mentawai, Nias",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "dictionary",
    citation:
      "Helfrich, O. L. & J. A. J. C. Pieters. 1891. Proeve van eene Maleisch-Nederlandsch-Enganeesch woordenlijst. <em>Tijdschrift voor Indische Taal-, Land- en Volkenkunde</em> 34. 539–604.",
    title: "Proeve van eene Maleisch-Nederlandsch-Enganeesch Woordenlijst",
    year: "1891",
    author: "O. L. Helfrich & J. A. J. C. Pieters",
    description: [
      {
        header: "Description",
        paragraphs: [
          "An Enggano-Indonesian-Dutch wordlist with a brief comparative wordlist of two Enggano varieties.",
        ],
      },
    ],
    id: "Helfrich & Pieters 1891",
    worldcat: "https://worldcat.org/en/title/1607509",
    subjectLanguage: "Enggano",
    analysisLanguage: "Dutch, Indonesian",
  },
  {
    sourceType: "dictionary",
    citation:
      "Helfrich, O. L. 1892. Aanvullingen en verbeteringen op de Maleisch-Nederlandsch-Enganeesch woordenlijst. <em>Tijdschrift voor Indische Taal-, Land- en Volkenkunde</em> 35. 228–233.",
    title:
      "Aanvullingen en verbeteringen op de Maleisch-Nederlandsch-Enganeesch woordenlijst",
    year: "1892",
    author: "O. L. Helfrich",
    description: [
      {
        header: "Description",
        paragraphs: ["Changes to the Helfrich & Pieters' (1891) wordlist."],
      },
    ],
    id: "Helfrich 1892",
    worldcat: "https://worldcat.org/en/title/1607509",
    subjectLanguage: "Enggano",
    analysisLanguage: "Dutch, Indonesian",
  },
  {
    sourceType: "dictionary",
    citation:
      "Helfrich, O. L. 1916. Nadere Bijdrage tot de kennis van het Engganeesch. <em>Bijdragen tot de Taal-, Land- en Volkenkunde van Nederlandsch Indië</em> 71(1/2). 472–555.",
    title: "Nadere Bijdrage tot de Kennis van het Engganeesch",
    year: "1916",
    author: "O. L. Helfrich",
    description: [
      {
        header: "Description",
        paragraphs: ["An Enggano-Dutch wordlist."],
      },
    ],
    id: "Helfrich 1916",
    worldcat: "https://worldcat.org/en/title/1114306552",
    subjectLanguage: "Enggano",
    analysisLanguage: "Dutch",
  },
  {
    sourceType: "dictionary",
    citation:
      "Kähler, Hans. 1987. <em>Enggano-Deutsches wörterbuch</em>. Berlin: Dietrich Reimer Verlag.",
    title: "Enggano-Deutsches Wörterbuch",
    year: "1987",
    author: "Hans Kähler",
    description: [
      {
        header: "Description",
        paragraphs: ["An Enggano-German dictionary."],
      },
    ],
    id: "Kähler 1987",
    worldcat: "https://worldcat.org/en/title/18191699",
    subjectLanguage: "Enggano",
    analysisLanguage: "German",
  },
  {
    sourceType: "description",
    citation:
      "Yoder, Brendon E. 2014. <em>Phonological and phonetic aspects of Enggano vowels</em> (SIL e-Books 62). Dallas: SIL International.",
    title: "Phonological and Phonetic Aspects of Enggano Vowels",
    year: "2014",
    author: "Brendon E. Yoder",
    description: [
      {
        header: "Description",
        paragraphs: [
          "An English description of Enggano phonetics/phonology. Includes an Enggano wordlist at the end.",
        ],
      },
    ],
    id: "Yoder 2014",
    worldcat: "https://worldcat.org/en/title/904101564",
    subjectLanguage: "Enggano",
    analysisLanguage: "English",
  },
  {
    sourceType: "grammar",
    citation:
      "Kähler, Hans. 2020. <em>Grammatical summary of Enggano</em> (Barnaby Burleigh & Mary Dalrymple, trans.). (Original work published in 1940.) (Unpublished manuscript.)",
    title: "Grammatical summary of Enggano",
    year: "2020",
    author: "Hans Kähler",
    description: [
      {
        header: "Description",
        paragraphs: ["An English translations of a German grammar of Enggano."],
      },
    ],
    id: "Kähler 2020",
    worldcat: "NA",
    subjectLanguage: "Enggano",
    analysisLanguage: "English",
  },
  {
    sourceType: "grammar",
    citation:
      "Crowley, Terry. 2020. <em>Grammatical sketch of Enggano</em>. (Unpublished manuscript.)",
    title: "Grammatical Sketch of Enggano",
    year: "2020",
    author: "Terry Crowley",
    description: [
      {
        header: "Description",
        paragraphs: ["An English grammar of Enggano."],
      },
    ],
    id: "Crowley 2020",
    worldcat: "NA",
    subjectLanguage: "Enggano",
    analysisLanguage: "English",
  },
  // Nasal
  {
    sourceType: "dictionary",
    citation:
      "Stokhof, W. A. L. 1987. <em>Holle lists: Vocabularies in languages of Indonesia</em>, vol. 10/2, <em>Southern Sumatra</em>. Canberra: The Australian National University.",
    title:
      "Holle Lists: Vocabularies in Langauges of Indonesia Volume 10/2 Southern Sumatra",
    year: "1987",
    author: "W. A. L. Stokhof",
    description: [
      {
        header: "Description",
        paragraphs: [
          "One of a series of sources regarding wordlists of languages of Indonesia. Contains wordlists on different varieties of Nasal, Lampung, South Barisan Malay, Serawai, Kaur, Semende.",
        ],
      },
    ],
    id: "Stokhof 1987a",
    worldcat: "https://worldcat.org/en/title/9687143",
    subjectLanguage:
      "Nasal, Lampung, South Barisan Malay, Serawai, Kaur, Semende",
    analysisLanguage: "English, Dutch",
  },
];

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 90%;
  margin: 5%;
  display: flex;
  justify-content: space-between;
`;

const IntroductionWrapper = styled.div`
  width: 70%;
`;

const IntroductionHeader = styled.div`
  width: 100%;
`;

const IntroductionTitle = styled.h1`
  font-size: 24px;
  text-align: center;
  display: block;
`;

const IntroductionDetails = styled.p`
  padding-top: 3px;
  text-align: center;
  font-weight: bold;
  display: block;
`;

const IntroductionBody = styled.div``;

const IntroductionBodyHeader = styled.h2`
  padding-top: 10px;
  font-size: 16px;
`;

const IntroductionBodyParagraph = styled.p`
  padding-top: 10px;
  line-height: 1.5;
`;

const SidebarWrapper = styled.div`
  width: 30%;
`;

const Welcome = () => {
  return (
    <Container>
      <IntroductionWrapper>
        <IntroductionHeader>
          <IntroductionTitle>
            Electronic Handbook of Sumatran Linguistics
          </IntroductionTitle>
          <IntroductionDetails>Blaine Billings</IntroductionDetails>
          <IntroductionDetails>May 2023</IntroductionDetails>
        </IntroductionHeader>
        <IntroductionBody>
          {false && (
            <div>
              <IntroductionBodyHeader>Acknowledgements</IntroductionBodyHeader>
              <IntroductionBodyParagraph>
                The number of people I wish to acknowledge for assistance and
                feedback on this resource are too many and my memory to poor to
                remember, but I will attempt here:
              </IntroductionBodyParagraph>
              <ul>
                <li>
                  Dr. Robert Blust, for motivating this entire project and
                  providing extensive feedback on the original proposal
                </li>
                <li>
                  Dr. Bradly McDonnell, for the many hours of discussion on what
                  shape this resource would take
                </li>
              </ul>
            </div>
          )}
        </IntroductionBody>
      </IntroductionWrapper>
      <SidebarWrapper></SidebarWrapper>
    </Container>
  );
};

export default Welcome;

import React from "react";
import styled from "styled-components";
import { hierarchy } from "../hierarchy";
import { useState } from "react";
import SCDTable from "../components/SCDTable";
import ReactHtmlParser from "react-html-parser";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 5% 10% 10% 10%;
`;

const EntriesHeader = styled.h1`
  text-align: center;
  margin: 0px 10px;
`;

const EntriesOptionsSection = styled.div`
  display: flex;
  margin: 0px 10px 10px 10px;
`;

const EntriesHierarchy = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
`;

const HierarchyHeader = styled.h2`
  text-align: center;
`;

const EntriesOptions = styled.div`
  //  display: flex;
  //  flex-direction: column;
  position: relative;
  margin: 20px 20px 0px 20px;
  flex: 1;
`;

const EntriesOptionsHeader = styled.div`
  text-align: center;
  font-size: 20px;
`;

const EntriesOptionsFields = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
`;

const EntriesOptionsField = styled.label`
  flex: 1;
  white-space: nowrap;
`;

const EntriesOptionsParagraph = styled.p``;

const LanguageLevel = styled.ul`
  list-style-type: none;
  margin-left: 0;
  padding-left: 30px;
`;

const LanguageContainer = styled.li`
  border-left: 1px solid black;
  &:last-of-type {
    border-left: 0px;
    & > div {
      border-width: 0 0 1px 1px;
    }
  }
`;

const Language = styled.div`
  width: fit-content;
  text-decoration: none;
  border: 1px solid #000;
  border-width: 0 0 1px 0px;
  border-radius: 0 0 0 10px;
  font-weight: ${(props) => props.filtering && "bold"};
`;

const LanguageInput = styled.input`
  margin-left: 0.25em;
  width: 1em;
  height: 1em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid black;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;

  &:checked {
    background-color: limegreen;
  }
`;

const EntriesWrapper = styled.div`
  border-width: 2px;
  border-style: solid;
  border-color: darkgray;
  background-color: white;
`;

const EntriesCard = styled.div`
  border-width: 2px;
  border-style: solid;
  border-color: darkgray;
  //  background-color: #fdfce5;
  margin: 5px 5px;
`;

const CardHeader = styled.h2`
  text-align: center;
  margin: 1% 0% 0% 0%;
`;

const CardDefinition = styled.p`
  margin: 0px 5px;
  font-size: 16px;
  text-align: center;
`;

const CardEvidence = styled.table`
  width: 96%;
  margin: 2%;
  border-collapse: collapse;
  height: 100%;
  table-layout: fixed;
`;

const EvidenceHead = styled.thead`
  height: 100%;
  width: 100%;
`;

const EvidenceBody = styled.tbody`
  height: 100%;
  width: 100%;
`;

const EvidenceRow = styled.tr`
  height: 100%;
  width: 100%;
`;

const EvidenceHeadersRow = styled.tr`
  height: 100%;
  width: 100%;
`;

const EvidenceHeader = styled.th`
  padding: 0px 5px;
  text-align: left;
  vertical-align: top;
  text-align: center;
  white-space: pre-wrap;
  display: ${(props) => props.visible === false && "none"};
  font-style: ${(props) => props.italic === true && "italic"};
  border-bottom: 1px solid black;
`;

const EvidenceLang = styled.th`
  margin: 0px;
  //  padding-left: ${(props) => `${props.lang_level * 10}px`};
  padding-left: ${(props) => props.lang_level > 0 && "10px"};
  font-size: 16px;
  text-align: left;
  vertical-align: top;
  white-space: pre-wrap;
`;

const EvidenceLangText = styled.div`
  //  width: ${(props) => props.lang_level === 0 && "fit-content"};
  padding-left: ${(props) => props.lang_level > 0 && "10px"};
  height: 100%;
  text-align: left;
  vertical-align: top;
  white-space: pre-wrap;
  border-left: ${(props) => props.lang_level > 0 && "1px solid black"};
  border-bottom: ${(props) => props.lang_level <= 1 && "1px solid black"};
`;

const EvidenceInfo = styled.td`
  padding: 0px 5px;
  text-align: left;
  vertical-align: top;
  white-space: pre-wrap;
  display: ${(props) => props.visible === false && "none"};
  font-style: ${(props) => props.italic === true && "italic"};
  border-bottom: 1px dotted black;
`;

const CardNotes = styled.div`
  margin: 2%;
`;

const CardNote = styled.p``;

const Entries = () => {
  const [viewAsTable, setViewAsTable] = useState(false);
  const [filterLanguage, setFilterLanguage] = useState("PNB");

  const identifyLanguage = (entry, language) => {
    let identified = {};

    if (entry.language === language) {
      identified = { ...entry };
    } else {
      identified = {
        ...entry.children.reduce((accumulator, child) => {
          let childIdentified = identifyLanguage(child, language);
          if (
            childIdentified.hasOwnProperty("language") &&
            childIdentified.language === language
          ) {
            return { ...childIdentified };
          } else {
            return accumulator;
          }
        }, {}),
      };
    }

    return identified;
  };

  const getHierarchy = (language) => {
    return (
      <LanguageContainer
        protoLanguage={language.children.length !== 0}
        key={language.language}
      >
        <Language filtering={filterLanguage === language.language}>
          {language.children.length !== 0 && (
            <LanguageInput
              type="checkbox"
              onChange={() => {
                setFilterLanguage(language.language);
              }}
              checked={filterLanguage === language.language}
            ></LanguageInput>
          )}
          {language.language}
        </Language>
        {language.children.length !== 0 && (
          <LanguageLevel>
            {language.children.map((innerLanguage, index) => (
              <React.Fragment key={index}>
                {getHierarchy(innerLanguage)}
              </React.Fragment>
            ))}
          </LanguageLevel>
        )}
      </LanguageContainer>
    );
  };

  const headersToFields = {
    SemDom: "semdom",
    Entry: "entry",
    Orth: "orth",
    Def: "def",
    Note: "note",
    Source: "src",
  };
  const headersToTableHeaders = {
    SemDom: "Semantic Domain",
    Entry: "Entry",
    Orth: "Orthography",
    Def: "Definition",
    Note: "Note",
    Source: "Source",
  };
  const headers = [
    //"SemDom",
    "Entry",
    "Orth",
    "Def",
    "Note",
    "Source",
  ];
  const [headersViews, setHeadersViews] = useState(
    headers.reduce((accumulator, header) => {
      return { ...accumulator, [header]: true };
    }, {})
  );

  const headers_width_share = {
    Lang: 1,
    SemDom: 1,
    Entry: 1,
    Orth: 1,
    Def: 3,
    Note: 1,
    Source: 1.5,
  };
  const calcHeaderWidthShare = (header) => {
    return (
      headers_width_share[header] /
      headers.reduce(
        (prev, curr) =>
          headersViews[curr] ? prev + headers_width_share[curr] : prev,
        0
      )
    );
  };

  const langSpan = (lang_name, lang_level) => {
    return (
      <EvidenceLangText lang_level={lang_level}>
        {lang_level === 0 ? lang_name : langSpan(lang_name, lang_level - 1)}
      </EvidenceLangText>
    );
  };

  const collectEvidenceHeaders = () => {
    return (
      <EvidenceHeadersRow>
        <EvidenceHeader style={{ width: `${calcHeaderWidthShare("Lang")}%` }}>
          Language
        </EvidenceHeader>
        {headers.map((header, index) => (
          <EvidenceHeader
            style={{ width: `${calcHeaderWidthShare(header)}%` }}
            visible={headersViews[header]}
            key={String(index) + header}
          >
            {headersToTableHeaders[header]}
          </EvidenceHeader>
        ))}
      </EvidenceHeadersRow>
    );
  };

  const collectEvidence = (entry, lang_level) => {
    return (
      <React.Fragment>
        {[...Array(entry.entry.split(";").length).keys()].map((index) => (
          <EvidenceRow
            key={entry.entry + String(index) + "row"}
            first={index !== 0}
          >
            {index === 0 && (
              <EvidenceLang
                rowSpan={entry.entry.split(";").length}
                lang_level={lang_level}
                style={{ width: `${calcHeaderWidthShare("Lang")}%` }}
              >
                {langSpan(entry.language, lang_level)}
              </EvidenceLang>
            )}
            {headers.map((header) => {
              if (!entry.hasOwnProperty(headersToFields[header])) {
                return (
                  <EvidenceInfo
                    visible={headersViews[header]}
                    key={entry.entry + String(index) + header}
                    style={{ width: `${calcHeaderWidthShare(header)}%` }}
                  ></EvidenceInfo>
                );
              }
              let splits = entry[headersToFields[header]].split(";");
              if (header === "Entry") {
                return (
                  (index === 0 || splits[index - 1] !== splits[index]) && (
                    <EvidenceInfo
                      style={{ width: `${calcHeaderWidthShare(header)}%` }}
                      visible={headersViews[header]}
                      key={entry.entry + String(index) + header}
                      italic={!entry.entry.startsWith("*")}
                      rowSpan={
                        splits.findLastIndex((a) => a === splits[index]) -
                        index +
                        1
                      }
                    >
                      {(splits[index].startsWith("*") ? "" : "  ") +
                        splits[index]
                          .replace("ʍ", "ow")
                          .replace("ʎ", "uy")
                          .replace("ᴇ", "ey")}
                    </EvidenceInfo>
                  )
                );
              } else if (header === "Source") {
                return (
                  <EvidenceInfo
                    style={{ width: `${calcHeaderWidthShare(header)}%` }}
                    visible={headersViews[header]}
                    key={entry.entry + String(index) + header}
                  >
                    {splits[index]}
                  </EvidenceInfo>
                );
              } else {
                return (
                  <EvidenceInfo
                    style={{ width: `${calcHeaderWidthShare(header)}%` }}
                    visible={headersViews[header]}
                    key={entry.entry + String(index) + header}
                  >
                    {header === "Orth" ? "<" : ""}
                    {ReactHtmlParser(splits[index])}
                    {header === "Orth" ? ">" : ""}
                  </EvidenceInfo>
                );
              }
            })}
          </EvidenceRow>
        ))}
        {entry.children
          .filter((innerEntry) => innerEntry.entry !== "")
          .map((innerEntry, index) => (
            <React.Fragment key={index}>
              {collectEvidence(innerEntry, lang_level + 1)}
            </React.Fragment>
          ))}
      </React.Fragment>
    );
  };

  const getInput = (header) => {
    return (
      <input
        type="checkbox"
        checked={headersViews[header]}
        onChange={() => {
          setHeadersViews({
            ...headersViews,
            [header]: !headersViews[header],
          });
        }}
      />
    );
  };

  return (
    <Container>
      <EntriesHeader>Entries</EntriesHeader>
      <EntriesOptionsSection>
        <EntriesHierarchy>
          <HierarchyHeader>Select Proto Language</HierarchyHeader>
          <LanguageLevel>
            {hierarchy.map((language) => getHierarchy(language))}
          </LanguageLevel>
        </EntriesHierarchy>
        <EntriesOptions>
          <EntriesOptionsHeader>Options</EntriesOptionsHeader>
          <EntriesOptionsFields>
            <EntriesOptionsField>
              <input
                type="checkbox"
                checked={viewAsTable}
                onChange={() => setViewAsTable(!viewAsTable)}
              />
              View as Table
            </EntriesOptionsField>
          </EntriesOptionsFields>
          <EntriesOptionsParagraph>Column Options:</EntriesOptionsParagraph>
          <EntriesOptionsParagraph>
            {getInput("Orth")}
            <b>Orthography</b>: View the entry's orthographic transcription as
            an additional column
          </EntriesOptionsParagraph>
          <EntriesOptionsParagraph>
            {getInput("Note")}
            <b>Note</b>: View any notes about the entry's dictionary
            transcription made by the e-HSL author as an additional column (only
            for synchronc forms, not reconstructions)
          </EntriesOptionsParagraph>
          <EntriesOptionsParagraph>
            {getInput("Source")}
            <b>Source</b>: View the citation for the entry's source as an
            additional column
          </EntriesOptionsParagraph>
          <EntriesOptionsParagraph>
            {getInput("Def")}
            <b>Definition</b>: View the entry's dictionary definition as an
            additional column
          </EntriesOptionsParagraph>
        </EntriesOptions>
      </EntriesOptionsSection>
      {
        viewAsTable /*&& (
        <SCDTable
          data={protoBatak
            .map((entry) => identifyLanguage(entry, filterLanguage))
            .filter((entry) => Object.keys(entry).length !== 0)
            .filter((entry) => entry.entry !== "")}
          headers={headers}
          headersToFields={headersToFields}
          headersViews={headersViews}
          type={"entriesTable"}
        />
      )*/
      }
      {
        !viewAsTable /*&& (
        <EntriesWrapper>
          {protoBatak
            .map((entry) => identifyLanguage(entry, filterLanguage)) // Grabs the desired PLanguage-level subentry for each entry
            .filter((entry) => Object.keys(entry).length !== 0) // Filters out non-existent entries, i.e. {}
            .filter((entry) => entry.entry !== "" && entry !== {}) // Filters out empty entries, i.e. entry.entry === ""
            .sort((entry1, entry2) => entry1.entry > entry2.entry)
            .map((entry, index) => (
              <EntriesCard key={entry + String(index)}>
                <CardHeader>{entry.entry}</CardHeader>
                <CardDefinition>{entry.def}</CardDefinition>
                <CardEvidence>
                  <EvidenceHead>{collectEvidenceHeaders()}</EvidenceHead>
                  <EvidenceBody>{collectEvidence(entry, 0)}</EvidenceBody>
                </CardEvidence>
                <CardNotes>
                  {entry.hasOwnProperty("notes") &&
                    entry.notes.map((note, index) => (
                      <CardNote key={String(index)}>{note}</CardNote>
                    ))}
                </CardNotes>
              </EntriesCard>
            ))}
        </EntriesWrapper>
      )*/
      }
    </Container>
  );
};

export default Entries;

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import Announcement from "../components/Announcement";
import Navbar from "../components/Navbar";
import Welcome from "./Welcome";
import Dict from "./Dict";
import Entries from "./Entries";
import Languages from "./Languages";
import Sources from "./Sources";
import About from "./About";
import Footer from "../components/Footer";

const SiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 12vw);
  margin: 2% 6% 6% 6%;
  background-color: white;
`;

const Home = () => {
  return (
    <SiteWrapper>
      <Announcement />
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/dict" element={<Dict />} />
          <Route path="/entries" element={<Entries />} />
          <Route path="/languages" element={<Languages />} />
          <Route path="/sources" element={<Sources />} />
          <Route path="/about" element={<About />} />
          <Route path="/" element={<Welcome />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </SiteWrapper>
  );
};

export default Home;

export const hierarchy = [
  {
    language: "PSum",
    children: [
      {
        language: "Gayo",
        children: [],
      },
      {
        language: "PB",
        children: [
          {
            language: "PNB",
            children: [
              {
                language: "Kluet",
                children: [],
              },
              {
                language: "Alas",
                children: [],
              },
              {
                language: "Singkil",
                children: [],
              },
              {
                language: "Dairi",
                children: [],
              },
              {
                language: "Karo",
                children: [],
              },
            ],
          },
          {
            language: "PCSB",
            children: [
              {
                language: "Simalungun",
                children: [],
              },
              {
                language: "PSB",
                children: [
                  {
                    language: "Toba",
                    children: [],
                  },
                  {
                    language: "Angkola",
                    children: [],
                  },
                  {
                    language: "Mandailing",
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        language: "PNBI",
        children: [
          {
            language: "Haloban",
            children: [],
          },
          {
            language: "Simeulue",
            children: [],
          },
          {
            language: "Leukon",
            children: [],
          },
        ],
      },
      {
        language: "PCBI",
        children: [
          {
            language: "Sigulai",
            children: [],
          },
          {
            language: "Nias",
            children: [],
          },
        ],
      },
      {
        language: "Mentawai",
        children: [],
      },
      {
        language: "Enggano",
        children: [],
      },
      {
        language: "Nasal",
        children: [],
      },
    ],
  },
];
